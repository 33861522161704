import React from "react"
import { FavoriteIcon } from "../assets/images/propertiesImages/favoriteIcon"
import { ShareIcon } from "../assets/images/propertiesImages/shareIcon"
import Spinner from "../assets/icons/spinner"
import useService from "./useService"

const ServiceActionButtons = ({ service }) => {
  const { isFav, isFavLoading, toggleFavorite, shareService, user } =
    useService(service)

  const isOwner = user?.id === service?.user?.id

  const handleToggleFavClick = e => {
    e.stopPropagation()
    toggleFavorite()
  }

  const handleShareClick = e => {
    e.stopPropagation()
    shareService()
  }

  return (
    <div className="d-flex" style={{height: '60px'}}>
      {service && (
        <button className="mx-2" onClick={handleShareClick}>
          <ShareIcon />
        </button>
      )}
      {!isOwner && (
        <button className="" onClick={handleToggleFavClick}>
          {isFavLoading && <Spinner />}
          {!isFavLoading && (
            <FavoriteIcon className="mx-2" color={isFav ? "#FC4B67" : ""} />
          )}
        </button>
      )}
    </div>
  )
}

export default ServiceActionButtons

import React from "react"
import { navigate } from "gatsby"
import placeholder from "../assets/images/placeholder1.svg"
import { ShareIcon } from "../assets/images/propertiesImages/shareIcon.js"
import { toggleModal } from "../actions/settingsActions"
import { connect } from "react-redux"
import { Service } from "../assets/images/serviceIcon"
import { Email } from "../assets/images/projectsIcons/email"
import { Phone } from "../assets/images/projectsIcons/phone"
import { FormattedMessage } from "react-intl"
import ArrowRight from "../assets/icons/arrowRight"
import useService from "./useService"
import ServiceActionButtons from "./serviceActionButtons"

const ServiceComponent = props => {
  const { serviceData, category } = props
  const { isFav, isFavLoading, toggleFavorite, user, shareService } =
    useService(serviceData)
  return (
    <>
      <div className="col-12 d-lg-none d-block">
        <div className="whiteBg br8 p-3 my-3 card-effect border-grey">
          <div className="d-flex align-items-end justify-content-start">
            <img
              src={serviceData.logo?.url || placeholder}
              style={{ aspectRatio: 1 / 1, maxHeight: 350, objectFit: 'cover'}}
              alt="service"
              width="100%"
              height="100%"
              className="br8 relative"
            />
            <div
              onClick={() => navigate(`/app/service/${serviceData.id}`)}
              className="secondaryLightBg py-2 br8 m-3 card-button absolute pointer"
            >
              <button className="d-flex justify-content-between align-items-center flex-wrap font-bold secondary card-button">
                <span className="px20 mx-2">
                  <FormattedMessage defaultMessage="Details" />
                </span>
                <span className="mx-2">
                  <ArrowRight size="1.5rem" />
                </span>
              </button>
            </div>
          </div>

          <div className="mx-lg-3 mx-2">
            <div className="d-flex align-items-center justify-content-between">
              <div className="font-bold px26 py-3">{serviceData.title}</div>
              <div>
                <button
                  className="mx-2 serviceShare" /*onClick={() => addToFavoriteHandler(productData.id)}*/
                >
                  <ShareIcon />
                </button>
              </div>
            </div>

            <div className="d-block my-2">
              <div className="darkGrey font-bold px16 d-flex align-items-center mb-3">
                <Service color={"#3BBA9C"} />
                <span className="mx-2 secondary">{category?.title}</span>
              </div>
              <div className="darkGrey font-bold px16 d-flex align-items-center mb-3">
                <Phone />
                <span className="mx-2">{serviceData.phone}</span>
              </div>
              <div className="darkGrey px16 d-flex align-items-center mb-3">
                <Email />
                <span className="mx-2">{serviceData.email}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 col-lg-12 col-xl-6 relative d-lg-block d-none ">
        <div
          className="whiteBg br8 my-3 card-effect border-grey"
          style={{ width: "100%" }}
        >
          <div className="d-flex">
            <div
              style={{ padding: 16, paddingRight: 0 }}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="w-100 h-100">
                <img
                  style={{
                    maxWidth: 200,
                    height: 200,
                    aspectRatio: 1 / 1,
                    objectFit: "cover",
                  }}
                  src={serviceData.logo?.url || placeholder}
                  alt="service"
                  width={200}
                  className="br8 "
                />
              </div>
            </div>
            <div className="w-100" style={{ padding: 16, paddingLeft: 0 }}>
              <div className="" style={{ marginLeft: 20 }}>
                <div className="d-flex justify-content-between">
                  <div className="font-bold px26-responsive py-lg-2 mb-3 noWrapEllipsis">
                    {serviceData.title}
                  </div>
                  <ServiceActionButtons service={serviceData} />
                </div>
                <div className="" style={{ marginTop: "18px" }}>
                  <div
                    className="darkGrey font-bold px16 d-flex align-items-center mt-2"
                    style={{ paddingTop: "8px" }}
                  >
                    <Service color={"#3BBA9C"} />
                    <span className="mx-2 secondary">{category?.title}</span>
                  </div>
                  <div
                    className="darkGrey font-bold px16 d-flex align-items-center"
                    style={{ paddingTop: "8px" }}
                  >
                    <Phone />
                    <span className="mx-2">{serviceData.phone}</span>
                  </div>
                  <div
                    className="darkGrey px16 d-flex align-items-center justify-content-between flex-column-row-16 m-top"
                    style={{ marginTop: "-8px" }}
                  >
                    <div className="darkGrey d-flex align-items-center mt-2">
                      <Email />
                      <span className="mx-2">{serviceData.email}</span>
                    </div>
                    <div
                      onClick={() => navigate(`/app/service/${serviceData.id}`)}
                      className="secondaryLightBg py-2 br8 card-button w-sm-100 pointer m-top"
                    >
                      <button className="d-flex justify-content-between align-items-center flex-wrap font-bold secondary card-button w-sm-100">
                        <span className="px20 mx-2">
                          <FormattedMessage defaultMessage="Details" />
                        </span>
                        <span className="mx-2">
                          <ArrowRight size="1.5rem" />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ user: { user } }) => ({ user })
export default connect(mapStateToProps, { toggleModal })(ServiceComponent)

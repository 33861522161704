import { navigate } from "gatsby-link"
import React from "react"
import { useState } from "react"
import { FormattedMessage } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { setGeneralModal, toggleModal } from "../actions/settingsActions"
import { error, success } from "../utils"
import dataApi from "../_api/dataApi"
import { myProfilePath } from "./header"
import ShareModal from "./modals/shareModal"

export default function useService(service) {
  const [isFav, setIsFav] = useState(service?.isFavorite)
  const [loading, setLoading] = useState(false)
  const user = useSelector(state => state?.user?.user)
  const dispatch = useDispatch()

  const shareService = () => {
    dispatch(
      setGeneralModal({
        open: true,
        Component: ShareModal,
        modalClassName: "confirm-modal",
        componentProps: {
          pathname: `/app/service/${service.id}`,
        },
      })
    )
  }
  const editService = (id) => {
    navigate(`/app/service/${id}/edit`)
  }
  const deleteService = () => {
    dataApi
      .deleteService(service.id)
      .then(() => {
        success(
          <FormattedMessage defaultMessage="Service was deleted successfully" />
        )
        navigate(myProfilePath)
      })
      .catch(() => {
        error()
      })
  }
  const openAuthModal = () => {
    dispatch(toggleModal({ open: true }))
  }
  const toggleFavorite = () => {
    if (!user?.id) {
      openAuthModal()
      return
    }
    setLoading(true)
    dataApi
      .toggleServiceFavorite(service?.id)
      .then(() => {
        setIsFav(isFav ? false : true)
        success(
          isFav ? (
            <FormattedMessage defaultMessage="Removed from favorite list" />
          ) : (
            <FormattedMessage defaultMessage="Added to favorite list" />
          )
        )
      })
      .catch(() => error())
      .finally(() => setLoading(false))
  }

  return {
    isFav,
    isFavLoading: loading,
    toggleFavorite,
    user,
    editService,
    deleteService,
    shareService,
  }
}
